import React, {useEffect} from 'react'
import { Tabs } from 'antd';
import { FaShippingFast  } from "react-icons/fa";
import { MdPayment  } from "react-icons/md";
import { RiLuggageCartFill } from "react-icons/ri";
import styled from 'styled-components'
import Shipping from './shipping'
import {useDispatch, useSelector} from 'react-redux'
import {CplaceOrder,getPlaceOrderInfo, getShippingInfo,  getVoucherTypeInfo, CpaymentMethod, Cshiping, checkOutSelector } from '../../api/checkOutSlice'
  import { loginSelector} from '../../api/authSlice'
  import { cartCouponSelector} from '../../api/cartSlice'

  import PlaceOrder from './placeOrder';
import PaymentMethod from './voucherType';

// import { AppleOutlined, AndroidOutlined } from '@ant-design/icons';
const { TabPane } = Tabs;

export default function Checkout({history}) {

  const dispatch = useDispatch()
const {  vMethod, activetab, vochertype, placeOrder  } = useSelector(checkOutSelector)

const {cartCoupons} = useSelector(cartCouponSelector)


useEffect(()=>{

    if (cartCoupons.length === 0 ) {
        history.push('/');
      }
      else{

        history.push('/checkout');
      }
    

}, [cartCoupons, history])

const ontab = (k, e) =>{
e.preventDefault();
  if(k === "2"){

    dispatch(CplaceOrder());
  } else {

    dispatch(Cshiping());
  }

}
    return (
      <div> 
        <CheckOutWrap className="container my-5">
              <Tabs
              
              activeKey={activetab}
              
               onTabClick={(k, e)=>ontab(k, e)}
               >

    {/* <TabPane
      tab={
        <span>
          <MdPayment />
          Voucher Type
        </span>
      }
      key="1"
    >
      <PaymentMethod info={getVoucherTypeInfo} actionMethod={ Cshiping}/>
    </TabPane> */}


    <TabPane
      tab={
        <span>
          <FaShippingFast />
          Shipping Address
        </span>
      }

      key="1"
    >
      <Shipping info={getShippingInfo} voucher={vMethod} actionMethod={CplaceOrder}/>
    </TabPane>

    <TabPane
      tab={
        <span>
          <RiLuggageCartFill />
          Place Order
        </span>
      }
      disabled= {placeOrder? false : true}
      key="2"
    >
    <PlaceOrder info={getPlaceOrderInfo}/>

    </TabPane>
   
  </Tabs>
        </CheckOutWrap>

                
        </div>
    )
}



const CheckOutWrap = styled.div`
min-height:68vh;
svg{

  font-size:1.1rem;
  margin:0 0.3rem;
}

`