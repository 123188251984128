import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Logo from '../images/logo2.png'

export default function Footer() {
    return (
  
      <FooterWrap>
  
      <div className="d-flex align-items-center justify-content-between px-5" style={{backgroundColor:"rgb(38 3 39) ",height:"170px"}}>
        <div className="d-flex align-items-center px-5" >
                <div className="pl-5">
                <img src={Logo} className="" alt="logo"/>   
                </div>    
                  <div className="container pl-5" > 
            
                        <ul style={{margin:0, padding:"1.2rem",listStyle:"none"}}>
                          <li><Link style={{color:'white',letterSpacing:"1px"}}>Privacy Policy</Link></li>
                          <li><Link style={{color:'white',letterSpacing:"1px"}}>Terms & Conditions</Link></li>
                          <li><Link style={{color:'white',letterSpacing:"1px"}}>Shipping Policy</Link></li>
                          <li><Link style={{color:'white',letterSpacing:"1px"}} to="/about">About Us</Link></li>
                        </ul>
                        </div>
        </div>

        <div className="d-flex align-items-center px-5"  >
                  <div className=""  > 
                <h4 style={{color:'white',letterSpacing:"2px"}}>Contact Us</h4>
                <p style={{color:'white',letterSpacing:"1px"}}>customercare@maalola.com<br/>+91 8296099974</p>
                </div>
                <div className="px-5" > 
                <p style={{color:'white',letterSpacing:"1px"}}>Luova gifting,<br/> C/o Maalola shared services </p>
                </div>
        </div>
            </div>

          <div style={{backgroundColor:"rgb(24 2 25)"}}>
              
            <p style={{color:"white", margin:0, padding:"0.5rem 0", textAlign:"center", }}>Copyright &#169; 2021 Luova.</p> 
      
          </div>
        </FooterWrap>
    )
}



const FooterWrap = styled.div`

/* position: absolute; */
bottom:0;
left:0;
width:100%;

`