import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import {message} from 'antd'
import keyUri from '../key'

export const initialState = {

    loading: false,
    hasErrors: false,
    scoupons:[],
    scatgoryCoupon:[],
    sAllCoupons:[],
    currentScoupon:null,
    couponOrder:[]

}

export const scouponSlice = createSlice({
    name:"scoupon",
    initialState,
    reducers:{

        getSCoupons: state =>{

            state.loading = true
        },

        getSCouponsSuccess: (state, {payload}) =>{
            state.scoupons = payload
            state.loading = false
            state.hasErrors = false
        
            
        },

        getCurrentCatagory : (state, {payload})=>{

            state.loading = false
            state.hasErrors = false
            state.scatgoryCoupon = payload

        },

      getAllScoupons : (state, {payload}) =>{

            state.loading = false
            state.sAllCoupons = payload
        },

        getCurrentSCoupon: (state, {payload}) =>{
     
            state.loading = false
        state.currentScoupon= payload

        },
        getCouponOrder:( state, {payload}) =>{

            state.loading = false
            state.couponOrder = payload

        },

        getScouponsFailure : state =>{

            state.loading = false
            state.hasErrors = true 
        }

    }
})

export const {getScouponsFailure,
    getCurrentCatagory,
     getSCouponsSuccess,
     getAllScoupons,
     getCurrentSCoupon,
      getSCoupons,
      getCouponOrder
      } = scouponSlice.actions
      
export const scouponsSelector = state => state.scoupons
export default scouponSlice.reducer

// const config = {
//     headers: {
//       'Content-Type': 'application/json'
//     }
//   };



export const  fetchScoupons = () => async dispatch =>{

    dispatch(getSCoupons())
    try {

  
    const {data} = await axios.get(keyUri.BACKEND_URI + '/socialcoupons');
    dispatch(getSCouponsSuccess(data.getcoupon))

    } catch (error) {

        dispatch(getScouponsFailure())
 
    }
    
}

export const  fetchCurrentScoupons = (id) => async dispatch =>{
    dispatch(getSCoupons())

    try {
        const {data} = await axios.get(keyUri.BACKEND_URI + `/activesocialcoupon/${id}`);
        console.log({active:data});
     
        dispatch(getCurrentCatagory(data))


    } catch (error) {
        dispatch(getScouponsFailure("Somthing went wrong"))

    }

}


export const  fetchAllSCoupons = () => async dispatch =>{
    dispatch(getSCoupons())

    try {
        const {data} = await axios.get(keyUri.BACKEND_URI + `/admin-socialcoupons`);
     console.log(data);
        dispatch(getAllScoupons(data))


    } catch (error) {
        dispatch(getScouponsFailure())

    }

}

export const  fetchOneScoupons = (id) => async dispatch =>{
    dispatch(getSCoupons())

    try {
        const {data} = await axios.get(keyUri.BACKEND_URI + `/sone-coupon/${id}`);
     console.log(data);
        dispatch(getCurrentSCoupon(data))


    } catch (error) {
        dispatch(getScouponsFailure("Somthing went wrong"))

    }

}

export const  creteOrderInfo = (data) => async dispatch =>{
    dispatch(getSCoupons())

    console.log(data);
    try {

        dispatch(getCouponOrder(data))


    } catch (error) {
        dispatch(getScouponsFailure("Somthing went wrong"))

    }

}

