import React from 'react'
import { createSlice } from '@reduxjs/toolkit';


export const initialState = {

    loading: false,
    shipping:true,
    vochertype:false,
    placeOrder:false,
    activetab:"1",
    shippingAdress:null,
    vMethod:null,
    order : null

}

export const checkOutSlice = createSlice({

    name:"checkout",
    initialState,
    reducers:{

        getShipping: state =>{

            state.loading = true
            state.shipping = true
            state.activetab = "1"

        },

        // getVoucherType: state =>{

        //     state.loading = true
        //     state.vochertype = true
        //     state.activetab = "1"
        // },

        getPlaceOrder: state =>{

            state.loading = true
            state.vochertype = true

            state.placeOrder = true
            state.activetab = "2"

        },
         shippingInfo: (state, {payload}) =>{

            state.loading = true
            state.shippingAdress = payload
    

        },
        vTypeinfo: (state, {payload}) =>{

            state.loading = true
            state.vMethod = payload
        },
        orderInfo: (state, {payload}) =>{

            state.loading = true
            state.order = payload
    

        },
    }
})

export const {
    getShipping,
    getVoucherType,
    getPlaceOrder,
    orderInfo,
    vTypeinfo,
    shippingInfo
} = checkOutSlice.actions

export const checkOutSelector = state => state.checkout
export default checkOutSlice.reducer
export const Cshiping = () => async dispatch =>{

    try {

        dispatch(getShipping())
        
    } catch (error) {
        
        console.log(error);
    }

}

export const CpaymentMethod = () => async dispatch =>{

try {

    // dispatch(getVoucherType())
    
} catch (error) {
    
    console.log(error);
}

}

export const CplaceOrder = () => async dispatch =>{

    try {

        dispatch(getPlaceOrder())
        
    } catch (error) {
        
        console.log(error);
    }

}

export const getPlaceOrderInfo = (info) => async dispatch =>{

    try {
        dispatch(orderInfo(info))
        
    } catch (error) {
        
        console.log(error);
    }
}

export const getVoucherTypeInfo = (info) => async dispatch =>{

    try {

        dispatch(vTypeinfo(info))
        
    } catch (error) {
        
        console.log(error);
    }

}


export const getShippingInfo = (info) => async dispatch =>{

    console.log(info);
    try {

        dispatch(shippingInfo(info))
        
    } catch (error) {
        
        console.log(error);
    }

}