import React from 'react'
import { Card, Row, Col , Image} from 'antd';
import { addCartCoupons, cartCouponSelector } from '../../../api/cartSlice'
import PButton from './payment'
import styled from 'styled-components'
import {loginSelector} from '../../../api/authSlice'
import { useSelector} from 'react-redux'
import Fade from 'react-reveal/Fade';
import {Link} from 'react-router-dom'
import Loader from '../../../shared/loader';
import {MdCardGiftcard} from 'react-icons/md'

const { Meta } = Card;

export default function Product({data}) {
    const {curr_user} = useSelector(loginSelector)

    return (
        <ProductWrap className="container py-sm-3">
        <Row gutter={30}>
            {
                data.map((item, i)=>{

return   <Col  key={i} span={6} className="my-3" >
<Fade duration={2000}>
            <Link to={`/products/${item._id}`}>
            <div className="coupo shadow pb-0" style={{ borderRadius: '2rem',width:"255px"}}> 

{/* // cover={<img alt="example" style={{height:"250px"}} src={`https://sycamoredev.herokuapp.com/static/${item.image}`} />} */}
   
   {
    item?.image? <img src={item?.image }  className="mx-auto d-block  " style={{height:"170px", width:"100%", display:'block', objectFit:'cover',  marginTop:' 1.5rem',marginBottom:'0rem',  borderRadius: '2rem 2rem 0 0'}} alt="imglogo" />
     : 
     <span className=" d-flex justify-content-center align-items-center text-center" style={{height:"140px", width:"100%",  display:'block', objectFit:'cover', borderRadius: '2rem 2rem 0 0' }}>
        <MdCardGiftcard className="voucher text-center"/>&nbsp;  
        </span>                    
   }


 
 {/* <hr/> */}
               
 <h6 className="text-center text-light pt-2" style={{background:'#9a4c9d',height:"46px", borderRadius: '0 0 2rem 2rem'}}>{item.name}</h6>
 
 {/* <PButton size="small"  type="primary" productId={item._id} total={item.price} user={curr_user} title={item.title}>shop</PButton>   */}

</div>
</Link>
     </Fade>
</Col>
                })
            }
            
            </Row> 
  
        </ProductWrap>
    )
}

const ProductWrap = styled.div`

hr{

    height:2px;
    border-bottom:2px solid white;
    /* border-style:dashed; */
    margin:0.5rem auto;
}

.load{

 text-align:center;
 margin:auto;
 display:block;

}

.voucher{

color:orange;
font-size:5rem;

}
`