import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Menu, Dropdown, Button } from 'antd';
import {LogoutOutlined  } from '@ant-design/icons';
import {FaUserAlt} from 'react-icons/fa'
import {logouthandler} from '../api/authSlice'
import {loginSelector} from '../api/authSlice'
import {Link} from 'react-router-dom'

export default function DropdownSec({username, userIcon, icon, history}) {

  const dispatch = useDispatch()

  const { college } = useSelector(loginSelector) 

  console.log(college);
    const menu = (
        <Menu>

           <Menu.Item >
            <Link to={`/user-editprofile`}><b style={{color:'#88448a'}}>My Profile</b> </Link> 
          </Menu.Item>

          <Menu.Item >
          <Link to={`/order`}><b style={{color:'#88448a'}}>   Order</b></Link>
         </Menu.Item>
      

     <Menu.Item >
   <div><LogoutOutlined style={{ transform:'translateY(-2.5px)',color:'#88448a' }}/> <Link   onClick={()=>dispatch(logouthandler('user'))}type="link" style={{color:'#88448a' }} >
      <b>Logout</b>
      </Link>
      </div>
    

          
          </Menu.Item>
          
         
        </Menu>
      );



    return (
      <Dropdown overlay={menu}>
      <Button size="middle" type="ghost" style={{border:"1px solid white",margin:'0 1.3rem'}}  shape="circle" onClick={e => e.preventDefault()}>
      <FaUserAlt style={{margin:'3px auto', fontSize:"1rem",  transform:'translateY(-1.5px)',color:'white' }} className="text-xl  text-brandColor mx-auto"/>
      </Button>
    </Dropdown>

    )
}
