import { configureStore } from '@reduxjs/toolkit';
import scouponsReducer from './sCouponSlice';
import cartReducer from './cartSlice';
import checkoutReducer from './checkOutSlice';
import couponSlice from './couponSlice'
import modalSlice from './modalSlice'
import productSlice from './productSlice'
import authSlice from './authSlice'
import bannerSlice from './bannerSlice'
import brandrSlice from './brandSlice'

export default configureStore({
  reducer: {

    scoupons:scouponsReducer,
    cart:cartReducer,
    checkout:checkoutReducer,
    coupons:couponSlice,
    modal:modalSlice,
    products:productSlice,
    auth:authSlice,
    banner:bannerSlice,
    brand:brandrSlice,

  },
});
