import React, {useState, useEffect } from 'react'
import styled from 'styled-components'
import LoginPopup from '../auth/loginpopup'
import Login from '../auth/login'
import {  logouthandler } from '../api/authSlice'
import { MdShoppingCart } from 'react-icons/md';
import { Badge, Button } from 'antd';
import {  useDispatch, useSelector } from 'react-redux';
import {cartCouponSelector} from '../api/cartSlice'
import CartView from '../components/social_coupons/cartView'
import {useLocation} from 'react-router-dom'
import {viewCartshowModal } from '../api/modalSlice'
import {UserOutlined  } from '@ant-design/icons';
import {  LogoutOutlined,  } from '@ant-design/icons';
import Dropdown from '../shared/dropdown'

export default function Topbar({isAuthenticate, user, balance }) {
  
const dispatch = useDispatch()
const { pathname } = useLocation()
const items = useSelector(cartCouponSelector)

console.log(user);

useEffect(()=>{

  if(pathname === "/checkout"){

     return dispatch(viewCartshowModal(false))
    
    }


})


    return (
        <TopbarWrap>

 <div className="container py-2">



<ul>
  {

    isAuthenticate &&  <li ><small style={{fontSize:"1rem", textTransform:'capitalize' }}>Welcome { user.user_name} </small></li>

  }  


       
   {user && <li><small style={{fontSize:"1rem" }}> Balance: &#8377; { balance } </small></li>} 
    <li>

   <Badge size="small" onClick={()=>dispatch(viewCartshowModal(true))}	 count={ items.cartCoupons.length }>
      <MdShoppingCart className="tcart text-white  " style={{paddingBottom:"3px",fontSize:"1.7rem"}}/>
    </Badge>
    </li>
    {
      // isAuthenticate ?
      //  <li>
      //     <small onClick={()=>dispatch(logouthandler('user'))} style={{cursor:"pointer",fontSize:"1rem" }}>Logout</small> 
      // </li>:
  
      !isAuthenticate &&  <li ><LoginPopup><Login/></LoginPopup></li>
       
    }
{
  !isAuthenticate && <li>
      <Button type="link" style={{color:"white", fontSize:"1rem",paddingLeft:"2px"}} href="/user-register">Register</Button> 
</li>
}
{/* {

isAuthenticate &&  <li>
<Button type="link" style={{color:"white", fontSize:"1rem",cursor:"pointer",paddingTop:"3px",paddingLeft:"3px"}}  href="/user-editprofile">Profile</Button> 
</li>

} */}
{
 
isAuthenticate && <Dropdown 
  username="user"
  userIcon={<LogoutOutlined style={{fontSize:"1.2rem", transform:"translateY(-3px)", fontWeight:"bold"}}/>}
  icon={< UserOutlined style={{fontSize:"1.2rem", transform:"translateY(-3px)", fontWeight:"bold"}}/>}/> }
</ul>

  <CartView  />    
  

  </div>      
        </TopbarWrap>
    )
}

const TopbarWrap = styled.div`

background-color:#88448a;
color:white;

.tcart{

  font-size:1.48rem;
  cursor:pointer;
  transform: translateY(1px);
}
.container{
   
    width:90%;
    margin:0 auto;
    
ul{
    margin:0;
    display:flex;
    padding:0 0.1rem;
    align-items:center;
    justify-content:flex-end;

    li{

        list-style:none;
        font-size:1.2rem;
        margin:0 1.3rem;


    }
}
}

`
