
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import {message} from 'antd'
import keyUri from '../key'

export const initialState = {

    loading: false,
    hasErrors: false,
    products:[],
    currentProduct:null
  

}

export const productSlice = createSlice({
    name:"product",
    initialState,
    reducers:{

        getProduct: state =>{

            state.loading = true
        },
        getProductSuccess: (state, {payload}) =>{

            state.loading = false
            state.products = payload
            
        },

        getCurrentProduct: (state, {payload}) =>{
console.log(payload);
            state.loading = false
            state.currentProduct = payload?.product
            
        },
        getProductFailure: (state, {payload}) =>{

            state.loading = false
            state.products = payload
            
        }

       
    }
})

export const {getProduct, getCurrentProduct, getProductSuccess, getProductFailure } = productSlice.actions
      
export const productSelector = state => state.products
export default productSlice.reducer


const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };



export const  fetchAllProducts = () => async dispatch =>{
      const key = "product"
      dispatch(getProduct())
      
    try {

        const {data} = await axios.get(keyUri.BACKEND_URI +'/products');

        dispatch(getProductSuccess(data))

    } catch ({response}) {

        dispatch(getProductFailure())
        response.data && message.success({ content: response.data.msg, key, duration: 2 });

    }
    
}

export const  fetchOneProduct = (id) => async dispatch =>{
    const key = "product"
    // message.loading({ content: 'Loading...', key });
    dispatch(getCurrentProduct())
    
  try {

      const {data} = await axios.get(keyUri.BACKEND_URI +`/products/${id}`);
     
      console.log(data);
      dispatch(getCurrentProduct(data))

  } catch ({response}) {

      dispatch(getProductFailure())
      response.data && message.success({ content: response.data.msg, key, duration: 2 });

  }
  
}





