import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {message, Modal} from 'antd'
import keyUri from '../../key'
import {useDispatch} from 'react-redux'
import {creteOrderInfo} from '../../api/couponSlice'


export default function Payment({info, user, cart, total,  payableAmt, vMethod, wallet  }) {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [iscatured, setCatured] = useState(false);


console.log(info);
  useEffect(()=>{

  iscatured && window.localStorage.removeItem('cart')
 iscatured && window.location.reload()

})


  const dispatch = useDispatch()

  const config = {
    headers: {
        Accept: "application/json",
        // "Content-Type": "application/json",
    }
  };




  const showModal = () => {
    setIsModalVisible(true);
 
  };

  const handleOk = () => {
    setIsModalVisible(false);
    window.localStorage.removeItem('cart')
    window.location.reload()
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    window.localStorage.removeItem('cart')
    window.location.reload()
  };



  const fullybuyWallet = async () => {

    setLoading(true)
      try {

        let orderdata = {
          info,
          user,
          cart,
          total,
          vMethod,
          successObj: {
          order_id:'By wallet'
          },
          payableAmt
        }
        
        const {data} = await axios.post(keyUri.BACKEND_URI+`/pay-by-wallet`, orderdata,  config )

        console.log(data);
        
        await axios.post(keyUri.BACKEND_URI + `/order`, orderdata, config)    

        dispatch(creteOrderInfo(orderdata))

        data && showModal();
        data && setLoading(false)

      } catch (error) {
        console.log('error');
        setLoading(false)

      }    
  }


  
   const razorPayPaymentHandler = async ()=> {

    const response = await axios.post(keyUri.BACKEND_URI + `/coupon-order` ,{info, user, cart, total, payableAmt, vMethod}, config);
    const { data } = response;
    
    const options = {
    key: 'rzp_live_YIIzN5inXUMWyh',
    name: 'Luova Gifting',
    order_id: data.id,
    handler: async (response) => {

      try {

        const key = "cart"
        message.loading({ content: 'Loading...', key });
        
        const paymentId = response.razorpay_payment_id;
        const url = keyUri.BACKEND_URI + `/capture/${paymentId}`;
        const captureResponse = await axios.post(url, {total, user,  payableAmt, wallet}, config)

        const successObj = JSON.parse(captureResponse.data)
        const captured = successObj.captured;

        console.log("App -> razorPayPaymentHandler -> captured", successObj)
        
       setCatured(captured)
      
       if(captured){

        message.success({content:"payment success!", key, duration: 2})
    
          let orderdata = {
            info,
            user,
            cart,
            total,
            vMethod,
            successObj,
            payableAmt,
      
        }
     
            await axios.post(keyUri.BACKEND_URI + `/order`, orderdata, config)    

              dispatch(creteOrderInfo(orderdata))
    
          }

          } catch (err) {
            console.log(err);
          }
    },

    prefill: {
      email: info.email,
      contact: info.phone_number,
    },

    theme: {
      color: "#5F2703",
    },
  };
  const rzp1 = new window.Razorpay(options);
  rzp1.open();
}

console.log(payableAmt);

  return (
    <div>
        <button 
        onClick={(payableAmt > 0) ? razorPayPaymentHandler : fullybuyWallet }
        className="btn btn-primary" >
          {loading ? "Loading..." : ( wallet && payableAmt === 0) ? "Proceed" : "Pay Now"}  
        </button>

        <Modal  visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
       <h6 className="text-secondary text-center py-2">
       Thank You for Shopping!
       </h6>
      </Modal>
    </div>
  )
}
