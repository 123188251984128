import React, {useState,useEffect} from 'react'
import { Form, Input, Button, Checkbox } from 'antd';
import {useDispatch, useSelector} from 'react-redux'
import {loginSelector} from '../../api/authSlice'
import {cartCouponSelector} from '../../api/cartSlice'

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 12 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3, span: 12 },
  };

export default function Shipping({actionMethod, info, voucher}) {

    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const {curr_user} = useSelector(loginSelector)
   const {cartCoupons} = useSelector(cartCouponSelector)
   const [isproduct, setProduct] = useState(false);
 

      // const inf = (values) =>{
      //     return <p>{values.email} <br/>
      //     {values.phone_number}</p>
      // }
// console.log(cartCoupons);


useEffect(()=>{
  const found = cartCoupons.find(item => item.type === "product" )
  if(found){
    setProduct(true)
  }

}, [cartCoupons])



    const onFinish = values => {
        dispatch(actionMethod());
            dispatch(info(values));
      };
    

      const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
      };

      useEffect(()=>{
        curr_user && form.setFieldsValue({
        
              email:curr_user .email,
              phone_number:curr_user.phone_number,
      
              });
        
      }, [curr_user])


    return (
        <div className="my-4">
      
            <Form
      {...layout}
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}

    >


<Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: 'Please input your email!' }]}
      >
        <Input />
      </Form.Item>


      <Form.Item
        label="Phone Number"
        name="phone_number"
        rules={[{ required: true, message: 'Please input your phone number!' }]}
      >
        <Input />
      </Form.Item>

    { isproduct &&  <Form.Item
        label="Address"
        name="address"
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input.TextArea />
      </Form.Item>

    }
      <Form.Item {...tailLayout}>
        <button className="btn   my-1 shadow-md"  style={{color:'#9a4c9d', backgroundColor:'#9a4c9d29', fontWeight:'500'}} type="primary" htmlType="submit">
          Next
        </button>
      </Form.Item>
    </Form>
        </div>
    )
}

