import React,{useState} from 'react'
import banner from '../images/about.jpg'
import styled from 'styled-components'

export default function About() {


    return (
        
        <div style={{minHeight:"70vh"}}>
       <div style={{height:"400px",backgroundImage:`url('${banner}')`}}>
       <AboutWrap>
         <div className="container flex">
           
                 {/* <h6> Contrary to popular belief, Lorem Ipsum is not simply random text.</h6> */}

         </div>
     </AboutWrap>
     </div>

        <div className="container my-5" style={{fontSize:"1rem",textAlign:"justify"}}>
        <h3  className=" pb-3 "> About Us</h3>

        <p><b>Luova Gifting</b> is the brand name and a vertical at Maalola Shared Services.</p>
        <p>We, at <b>Luova Gifting,</b> provide a one point RnR solution for your 
        stakeholders both internal and external, viz. employees and channel 
        partners / clients / customers. </p>
       <p>Equipped with over a 100+ brand vouchers which are not restricted to 
        redemption in Indian demography, but also with brand vouchers which can 
        be redeemed across specific destinations globally by having access to their 
        local store. </p>
        <p>We provide centralized control and access solutions for business situations 
        like one point RnR solution to corporates and their branches distributed 
        across various geographical location with centralized control and access to a 
         select few.</p>
        <p>Enabling Corporates to track and ensure that the reward reaches the 
        deserving stellar performer and won’t get lost in the maze while reaching the
         achiever at the bottom of the pyramid.</p>
        <p>Easy extraction and reconciliation of spent data analysis
        Flexibility of the tool with customized thereby ensuring that all the 
        stakeholders, i.e. the Corporate, Customer / Channel Partner and us, are in 
        win-win situation.</p>
        <p>Fully customizable dashboard to have bird's view of the repeat sales orders / 
        potential drivers / inventory turns or any other measurement parameter of 
        choice.</p>
        <p>Close monitoring of Sales campaigns and data visibility to reward the stellar 
        performers at the bottom of the pyramid.</p>
       <p>Customizable solutions deployment in a limited time period.</p>
     </div>
     </div>
   
    )
}

const AboutWrap = styled.div`

position:absolute;
text-align:center;
padding:4rem 4rem;

`