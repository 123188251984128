import React, {useEffect} from 'react'
import {fetchAllProducts, productSelector} from '../../../api/productSlice'
import {  useDispatch, useSelector } from 'react-redux';
import Loader from '../../../shared/loader'
import Products from './product'
// import ViewMore from './viewMore'
// import Footer from '../../../global/footer'
export default function Index() {
    const dispatch = useDispatch()

    // const {scoupons, loading} = useSelector(scouponsSelector)
    const { products, loading } = useSelector(productSelector)


    useEffect(()=>{
        // dispatch(fetchScoupons())
        dispatch(fetchAllProducts())
        
    }, [dispatch])

    
   
    return (
        <div className="container mt-5" style={{minHeight:"70vh"}}>
                <h2>Products & Gifts</h2>
            <hr/>
            {
                loading? <Loader/> : <Products data={products}/>
            }
      
      {/* <ViewMore />  */}
        
        </div>
    )
}
