import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import {message} from 'antd'
import keyUri from '../key'
const token = localStorage.getItem('token') ?
  JSON.parse(localStorage.getItem('token')) : null
  console.log(token);

  
export const initialState = {
  loading: false,
  hasErrors: false,
  logpopUp: false,
  isAuthenticate:false,
  balance: 0,
  curr_user: null,
  token:token
}
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getLogin: state => {
      state.loading = true
    },
    getSubmit:state =>{
      state.loading = false
    },
    getLoginSuccess: (state, { payload }) => {
      state.token = payload
      state.loading = false
      state.logpopUp= false
    },
    getLoginFailure: state => {
      state.loading = false
      state.hasErrors = true
    },
    logout: state => {
      state.loading = false
      state.isAuthenticate = false
      state.logpopUp = false
    },
    showlogpopup: (state, { payload }) => {
      state.logpopUp = payload
    },
    getCurrentUser: (state, { payload }) => {
      state.loading = false
      state.curr_user = payload.user
      state.balance = payload.total
      state.isAuthenticate = payload.user ? true : false
    state.logpopUp = false;
    }
  }
})
export const { getLogin,getSubmit,
  showlogpopup, logout,
   getLoginSuccess,  getLoginFailure, getCurrentUser } = authSlice.actions
export const loginSelector = state => state.auth
export default authSlice.reducer
const config = {
  headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
  }
};
export const current_user =(token) => async dispatch=>{
  const loginConfig  = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  try {
    const {data} = await axios.get(keyUri.BACKEND_URI + `/profile`, loginConfig)
    dispatch(getCurrentUser(data))
  } catch (error) {
  }
  }
export const fetchLogin = (logIndata) => async dispatch => {
  dispatch(getLogin())
const key = "login"
message.loading({content:"loading...",  key})
try {
    const { data } = await axios.post(keyUri.BACKEND_URI +'/auth', logIndata, config)
    console.log(data);
    data && message.success({content:"Login successfully", duration:2, key})
    localStorage.setItem("token", JSON.stringify(data))
   window.location.reload()
    dispatch(getLoginSuccess(data))
  } catch ({response}) {
    response.data && message.error({content:response.data.msg, duration:2, key})
    dispatch(getLoginFailure())
  }
}

export const fetchUserRegister = (Regdata) => async dispatch => {
const key = "login"
message.loading({content:"loading...",  key})

dispatch(getLogin())
  try {
    const { data } = await axios.post(keyUri.BACKEND_URI +'/register', Regdata, config)
    data && message.success({content:"Register successfully", duration:2, key})
    localStorage.setItem("token", JSON.stringify(data))
   window.location.reload()
    dispatch(getLoginSuccess(data))
  } catch ({response}) {
    response.data && message.error({content:response.data.msg, duration:2, key})
    dispatch(getLoginFailure())
  }
}



export const logouthandler = (data) => async dispatch => {
  localStorage.removeItem('token');
  dispatch(logout())
  window.localStorage.removeItem('cart')
  window.location.href = '/home'

}


export const showModal = (ispopup) => async dispatch => {
  dispatch(showlogpopup(ispopup))
}


export const addUserCoupon = (values) => async dispatch =>{
      const key = 'create';
      dispatch(getLogin())
      message.loading({ content: 'loading...', key })
    try {
      const {data} = await axios.post(keyUri.BACKEND_URI +'/addCoupon', values, config);
      data &&  message.success({ content: data.msg, key, duration: 2 });
        dispatch(getLoginSuccess(data.token))
      localStorage.setItem("token", JSON.stringify(data.token))
      
    window.location.reload()

    } catch ({response}) {
      response.data.msg && message.error({ content: response.data.msg, key, duration: 2 });
      dispatch(getLoginFailure())
    }
}

export const  updateUser = (id, values) => async dispatch =>{
  console.log(values);
  const key = "user"
  dispatch(getLogin())
  message.loading({ content: 'loading...', key })

try {
    const {data} = await axios.put(keyUri.BACKEND_URI +`/user/${id}`, values, config);
    console.log(data);
    
    data && message.success({ content: data.msg, key, duration: 2 });
    dispatch(getLoginSuccess(data))
    window.location.reload()
} catch ({response}) {

    dispatch(getLoginFailure())
    response.data && message.success({ content: response.data.msg, key, duration: 2 });

}
}

export const  forgetPassword = (isemail) => async dispatch =>{
  console.log(isemail);
  const key = "user"
  dispatch(getLogin())
  message.loading({ content: 'loading...', key })

try {
    const {data} = await axios.post(keyUri.BACKEND_URI +`/forgetpassword`, isemail);
    
    data && message.success({ content: data.msg, key, duration: 5 });
    dispatch(getLoginSuccess(data))

} catch ({response}) {
    dispatch(getLoginFailure())
    response.data && message.error({ content: response.data.msg, key, duration: 2 });

}
}


export const ContactEmail = (values) => async dispatch => {
  const key = "login"
  
  dispatch(getLogin())
    try {
      const { data } = await axios.post(keyUri.BACKEND_URI +'/contact', values, config)
      data && message.success({content:"Email sent Successfull", duration:2, key})
      data &&  dispatch(getSubmit())
      setTimeout(() => {
        window.location.href = "/home"
      }, 2000) 
    } catch ({response}) {
      response.data && message.error({content:response.data.msg, duration:2, key})
      // dispatch(getLoginFailure())
    }
  }
  