import { createSlice } from '@reduxjs/toolkit';

export const initialState = {

    visible: false,
    visibleViewcart:false
  

}

export const modalSlice = createSlice({
    name:"coupon",
    initialState,
    reducers:{

        getVisible: (state, {payload}) =>{

            state.visible = payload
        },

        getviewcartVisible:(state, {payload})=>{

           state.visibleViewcart = payload


        }

       
    }
})

export const {getVisible, getviewcartVisible} = modalSlice.actions
      
export const modalSelector = state => state.modal
export default modalSlice.reducer


export const  showModal = (data) => async dispatch =>{
    console.log(data)
    
    try {

  dispatch(getVisible(data))
  

    } catch (error) {

    console.log(error);
 
    }
    
}

export const  viewCartshowModal = (data) => async dispatch =>{


    
    try {

  dispatch(getviewcartVisible(data))
  

    } catch (error) {

    console.log(error);
 
    }
    
}