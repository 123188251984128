import React,{useState} from 'react'
import { FiLogOut } from "react-icons/fi";
import { Modal, Button } from 'antd';
import {useDispatch, useSelector} from 'react-redux'
import {showModal, loginSelector} from '../api/authSlice'

export default function Loginpopup({children}) {

const [visible, setVisible] = useState(false)

const dispatch = useDispatch();
const isLogin = useSelector(loginSelector)



    
     const handleOk = e => {
        console.log(e);
        dispatch(showModal(false));

      };
    
     const handleCancel = e => {
        console.log(e);
        dispatch(showModal(false));

      };

    return (
        <>
        <small style={{cursor:"pointer",fontSize:"1rem"}} onClick={()=>dispatch(showModal(true))}>&nbsp;Login</small>
     
        <Modal
          title="User Login"
          visible={isLogin.logpopUp}
          onOk={()=>handleOk()}
          onCancel={()=>handleCancel()}
          footer={null}
        >
          {children}
        </Modal>
      </>
    )
}
