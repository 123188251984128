import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { message } from 'antd'
import keyUri from '../key'

export const initialState = {

    loading: false,
    hasErrors: false,
    coupons: [],
    currentCoupon: null,
    couponOrder:[]

}

export const couponSlice = createSlice({
    name: "coupon",
    initialState,
    reducers: {

        getCoupons: state => {

            state.loading = true
        },

        getCouponsSuccess: (state, { payload }) => {

            state.coupons = payload
            state.loading = false
            state.hasErrors = false


        },
        getCurrentCoupon: (state, { payload }) => {
            state.loading = false
            state.currentCoupon = payload

        },

        getCouponsFailure: state => {

            state.loading = false
            state.hasErrors = true
        },
        getOrderInfo: (state, { payload }) => {
            state.loading = false
            state.couponOrder = payload

            
        }
        

    }
})

export const { getCouponsFailure,
    getCouponsSuccess,
    getCurrentCoupon,
    getOrderInfo,
    getCoupons } = couponSlice.actions

export const couponsSelector = state => state.coupons
export default couponSlice.reducer

const config = {
    headers: {
        'Content-Type': 'application/json'
    }
};

export const fetchCoupons = () => async dispatch => {
    const key = 'loading';
    dispatch(getCoupons())
    try {


        const { data } = await axios.get(keyUri.BACKEND_URI + '/coupon');

        dispatch(getCouponsSuccess(data))

    } catch ({ response }) {

        response.data.msg && message.error({ content: response.data.msg, key, duration: 2 });

        dispatch(getCouponsFailure())

    }

}




export const fetchCurrentCoupon = (id) => async dispatch => {
    const key = 'loading';

    dispatch(getCoupons())
    try {
        const { data } = await axios.get(`/api/coupon/${id}`);
        dispatch(getCurrentCoupon(data))


    } catch ({ response }) {

        response.data.msg && message.error({ content: response.data.msg, key, duration: 2 });

        dispatch(getCouponsFailure())

    }

}

export const  creteOrderInfo = (data) => async dispatch =>{
    dispatch(getCoupons())

    try {

        window.localStorage.removeItem('cart')
        dispatch(getOrderInfo(data))
        

    } catch (error) {
        dispatch(getCouponsFailure("Somthing went wrong"))

    }

}



