import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {fetchAllProducts, productSelector} from '../../../api/productSlice'
import Loader from '../../../shared/loader'
import {  Button, } from 'antd';
import Products from './product'
import { Link } from 'react-router-dom';

export default function ProductIndex() {

const {products, loading} = useSelector(productSelector)
const dispatch = useDispatch();

    useEffect(()=>{

dispatch(fetchAllProducts())

    }, [dispatch])

    let Pproducts = products.filter(item => item.hasPriority === true).sort((a,b)=>a.priority - b.priority)
    
    console.log(Pproducts);


    return (
        <div className="container mb-5">

            <h2>Products</h2>
            <hr/>
           
           {
              loading ? <Loader/> : <Products data={Pproducts}/>
           } 


<Button 
         style={{backgroundColor:'white', border:'1px solid #9a4c9d ', color:'#9a4c9d', fontSize:"1rem", float:"right", clear:"left"}}
          type="primary">
              <Link to="/products-gifts">
          View More
          </Link>
       </Button>
<br/>
 
        </div>
        
    )
}
