import React from 'react'
import Header from './header'
import Scoupons from '../social_coupons'
import Products from './products'

export default function Home() {

    

    return (
        <div style={{minHeight:"70vh"}}>
           <Header/> 
           <Scoupons/>
           <Products/>
          
        </div>
    )
}
