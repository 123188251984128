import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {
    Form,
    Input,
  
    Button,

  } from 'antd';

  import {updateUser, loginSelector,current_user} from '../api/authSlice'
import { useDispatch, useSelector } from 'react-redux';

const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
  };

export default function RegisterUser({history}) {
    const [form] = Form.useForm();
    const {isAuthenticate, loading,curr_user} = useSelector(loginSelector)
    console.log(curr_user);
    const {id} = useParams()
    // console.log(id);
    const dispatch = useDispatch()


    // useEffect(()=>{
  
    //     dispatch(current_user(id))
        
  
    //     }, [dispatch])
       
  
    useEffect(()=>{
  
      form.setFieldsValue({
            user_name:curr_user && curr_user.user_name,
            email:curr_user && curr_user.email,
            phone_number:curr_user && curr_user.phone_number,
            address:curr_user && curr_user.address,
        
            });
      
    }, [curr_user])


    const onFinish = (values) => {
      
        const imgdata = {

            user_name:values.user_name,
            email:values.email,
            address:values.address,
            phone_number:values.phone_number,
            oldpassword:values.oldpassword,
            new_password:values.new_password,
            confirm_password:values.confirm_password,  

        }
        console.log(imgdata);
      curr_user &&  dispatch(updateUser(curr_user._id,imgdata))
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };


    return (
        <div className="container mb-5 my-5" style={{minHeight:"63vh"}}>

          <h3 className="py-3 text-center text-secondary">Profile Edit</h3>
             <Form {...layout} 
                 form={form}
             name="nest-messages"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed} >


      <Form.Item name='user_name'
       label="Name" 
       rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item
    
        name="email"
        label="E-mail"
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please input your E-mail!',
          },
        ]}
      >
        <Input disabled />
      </Form.Item>
    
      
      <Form.Item name='phone_number'
       label="Phone Number" 
       rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item name="address" label="Address">
        <Input.TextArea />
      </Form.Item>


      <Form.Item
                                label={<p className=' w-40 text-left m-0'>Old Password </p>}
                                name="oldpassword"  
                                rules={[
                                {
                                    min: 8,
                                    // required: true,
                                    message: 'password must be at list 8 character!',
                                },
                                ]}
                                hasFeedback
                            >
                                <Input.Password style={{ width: '100%'}} placeholder="Old Password" />
                            </Form.Item>

                            <Form.Item
                                label={<p className='w-40 text-left m-0'>New Password  </p>}
                                name='new_password'
                                className='  border-none'
                                rules={[
                                {
                                    min: 8,
                                    // required: true,
                                    message: 'password must be at list 8 character!',
                                },
                                ]}
                                hasFeedback
                            >

                            <Input.Password placeholder="New Password" />
                           </Form.Item>

                            <Form.Item
                                label={<p className=' w-40 text-left m-0'>Confirm Password </p>}
                                name='confirm_password'
       
                                className='  border-none'
                                 dependencies={['new_password']}
                                 hasFeedback
                                 rules={[
                                  
                                   ({ getFieldValue }) => ({
                                     validator(rule, value) {
                                       if (!value || getFieldValue('new_password') === value) {
                                         return Promise.resolve();
                                       }
                         
                                       return Promise.reject('The two Passwords that you Entered Do Not Match!');
                                     },
                                   }),
                                 ]}
                               >
                                 <Input.Password  placeholder="Confirm Password" />
                            </Form.Item>

      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 5 }}>
      <button className="btn  float-right my-1 shadow-md"  style={{color:'#9a4c9d', backgroundColor:'#9a4c9d29', fontWeight:'500'}} type="primary" htmlType="submit">
          Submit
        </button>
      </Form.Item>
    </Form>
        </div>
    )
}
