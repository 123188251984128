import React,  {useEffect} from 'react'
import Navbar from './navbar'
import Footer from './footer'
import Topbar from './topbar'
import Loader from '../shared/loader'
import { useSelector, useDispatch } from 'react-redux';
import {  loginSelector, current_user } from '../api/authSlice' 
import {cartCouponSelector} from '../api/cartSlice'



export default function AppWrap({children}) {
// const user = useSelector(loginSelector)
const { curr_user, loading, isAuthenticate, balance } = useSelector(loginSelector) 
// const { isUsedWalletBallance, isUsedWallet } = useSelector(cartCouponSelector)  

  const dispatch = useDispatch();
let token = JSON.parse(localStorage.getItem('token'))



useEffect(()=>{
  token && dispatch(current_user(token))
        }, [dispatch])
    return (
        <>
        {
          loading? <Loader/> : <>
          <Topbar user={curr_user}
           isAuthenticate={isAuthenticate}
            balance={ balance} />
          <Navbar />
         {children}
          <Footer/>
          </>
        }
        </>
    )
}





