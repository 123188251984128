import React, { useEffect } from 'react'
import styled from 'styled-components'
import banner from '../../images/banner.jpg'
import {Form} from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { bannerSelector, fetchAllBanner} from '../../api/bannerSlice'

export default function Header() {

    const dispatch = useDispatch()
const {all_banner, loading} = useSelector(bannerSelector)
const [form] = Form.useForm();

// const {id} = useParams()
console.log(all_banner);


useEffect(()=>{

    dispatch(fetchAllBanner())
    
    }, [dispatch])

    return (
        <HeaderWrap className="container-fluid image" style={{backgroundImage:`url('${all_banner.image}')`}}>
            <div className="text">
            <h6 className="m-0">{all_banner.title}</h6>
            <h1 className="m-0 text-uppercase"><b><i>{all_banner.sub_title}</i></b></h1>
            </div>
        </HeaderWrap>
    )
}

const HeaderWrap = styled.div`

background-size:100%;
height:300px;
position:relative;


&::after{
    position: absolute;
    
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    background:linear-gradient(98deg,rgba(2,0,36,0) 0%,rgb(236 236 236 / 78%) 95%,rgb(255 255 255 / 85%) 100%);
    z-index: 1;
    width: 100%;
    height: 100%;
}

.text{
position:absolute;
top:50%;
right:20%;
text-align:right;
z-index: 2;

h1{
    color:#88448a;
    font-size:2.5rem;
font-weight:400;
}
h6{
    font-size:1.3rem;

}
}

` 