import React, {useEffect} from 'react'
import { fetchAllSCoupons, scouponsSelector } from '../../api/sCouponSlice'
import {fetchAllBrands, brandSelector} from '../../api/brandSlice'

import {  useDispatch, useSelector } from 'react-redux';
import Loader from '../../shared/loader'
import Brand from './scoupon'
// import ViewMore from './viewMore'

export default function Index() {


    const dispatch = useDispatch()

    const { sAllCoupons } = useSelector(scouponsSelector)
    const { brands, loading } = useSelector(brandSelector)


    useEffect(()=>{
        dispatch(fetchAllSCoupons())
        dispatch(fetchAllBrands())
    }, [dispatch])


    
   
    return (
       
        <div className="container mt-5" style={{minHeight:"70vh"}}>
                <h2>E-Vouchers</h2>
            <hr/>
            {
                loading? <Loader/> : <Brand brands={brands} sAllCoupons={sAllCoupons}/>
            }
      
      {/* <ViewMore />  */}

     
        
        </div>
       
    )
}
