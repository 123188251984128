import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import moment from 'moment'
import {
    Form,
    Input,
  
    Button,

  } from 'antd';

  import {updateUser, loginSelector,current_user} from '../../api/authSlice'
import { useDispatch, useSelector } from 'react-redux';

const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
  };

export default function RegisterUser({history}) {
    const [form] = Form.useForm();
    const {isAuthenticate, loading,curr_user} = useSelector(loginSelector)
    console.log(curr_user);
    const {id} = useParams()
    // console.log(id);
    const dispatch = useDispatch()
    const array = curr_user?.orders?.concat(curr_user?.productorders)
    console.log(array);

    return (
        <div className="container mb-5 my-5 " style={{minHeight:"63vh"}}>
            <h4 className=" pl-10 mb-4 ">Orders</h4>
           
            
        {
           array?.map((item, k)=>{
                console.log(item);
                return  <div key={k}  class="container mb-3 " style={{background:"#fafafa",color:"black"}}>
                    <div class="row">

                        <div class="col-4 mt-3 mb-3">
                        <p><b>OrderId :</b>{item.sycOrderId}</p>
                         <p><b>Date :</b>{item && moment(item.createdAt).format('DD/MM/YYYY')}</p>
                          <p><b>Address :</b>{item.address}</p>
                          <p><b>Total Amount :</b>{item.total}</p>
                        </div>
                        <div class="col-8">
                            
                        <div class="container mt-3">
                        <div class="row">
                                <div class="col-3">
                                <p><b>  Item</b></p>
                                </div>
                                <div class="col-3">
                                <p><b>Unit Price </b></p>
                                    </div>
                                    <div class="col-3">
                                    <p><b>Quantity</b></p>
                                    </div>
                                    <div class="col-3">
                                    <p><b>Sub Total</b></p>
                                    </div>
                                    </div>
                        </div>
                        { 
                           (item?.scouponsOrder) ?
                           
                            item?.scouponsOrder.map((sitem,i)=>{
                         return <div key={i} class="container">
                           
                            <div class="row">
                                 <div class="col-3">
                                 <p>{sitem?.name}</p>

                                </div>
                                <div  class="col-3">

                                <p>{sitem?.value}</p>
                                 </div>
                                <div  class="col-3 ">

                                <p>{sitem?.quantity}</p>

                              </div>
                              <div  class="col-3 ">

                                <p>{(sitem?.quantity) * (sitem?.value) }</p>

                              </div>

                             </div>
                         </div>
                     
                             }) : (item?.products?.map((pitem,i)=>{
                            //    return console.log({k:pitem});
                                return <div key={i} class="container">
                              
                 
                                   <div class="row">
                                        <div class="col-3 ">
                                        <p>{pitem?.name}</p>
       
                                       </div>
                                       <div  class="col-3">
       
                                       <p>{pitem?.value}</p>
                                        </div>
                                       <div  class="col-3 ">
       
                                       <p>{pitem?.qty}</p>
       
                                     </div>

                                     <div  class="col-3 ">
       
                                       <p>{(pitem?.qty) * (pitem?.value) }</p>

                                     </div>
       
                                    </div>
                                </div>
                            
                                    }) 
 )

                        }

                        </div>
                   
                    </div>
                    </div>


        })
    }


        </div>
      
    )
}

