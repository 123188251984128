import React from 'react';
import Home from './components/home'
import ScDetails from './components/social_coupons/sc_detail'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import AppWrap from './global/appwrap'
import './App.css';
import Checkout from './components/checkout'
import Login from './auth/login'
import Register from './auth/registerUser'
import EditProfile from './auth/profileEdit'
import PDetails from './components/home/products/pdetails'
import EVoucher from './components/social_coupons/viewMore'
import  Products from './components/home/products/viewmore'
import Contact from './components/social_coupons/contact'
import Order from './components/order/index'
import About from './components/about'
function App() {
  return (
     <Router>
    <main className="App">

    <AppWrap>


  <Route path="/home"  component={Home} exact/>
  <Route path="/e-vouchers" component={EVoucher} exact/>
  <Route path="/contact"  component={Contact} exact/>
  <Route path="/products-gifts" component={Products} exact/>
  <Route path="/socialcoupon/:id" component={ScDetails} exact/>
  <Route path="/products/:id" component={PDetails} exact/>
  <Route path="/checkout" component={Checkout} exact/>
  <Route path="/user-login" component={Login} exact/>
  <Route path="/user-register" component={Register} exact/>
  <Route path="/user-editprofile" component={EditProfile} exact/>
  <Route path="/order" component={Order} exact/>
  <Route path="/about" component={About} exact/>
  <Route path="/"  component={Home} exact/>


        
    </AppWrap>
     
    </main>
    </Router>

 
  );
}

export default App;
